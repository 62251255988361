import React, { Component } from 'react';
import { withSize } from 'react-sizeme';
import Card from '../components/Card/Card';
import Layout from '../components/Layout';
import PageTitleBanner from '../components/PageTitleBanner';

import ConfirmationStyled from '../components/page-styles/confirmation-styles';

class Confirmation extends Component {
    render() {
        const { size } = this.props;

        let thankYou = {
            bgColor: 'white',
            bodyTextColor: 'gray',
            button: {
                buttonSidePadding: '24px',
                buttonVerticalPadding: '15px',
                color: 'yellow',
                isExternal: false,
                text: 'Back to Home',
                url: '/',
            },
            buttonText: 'Back to Home',
            centerAlign: false,
            dividerColor: 'gray',
            hasBorderRadius: false,
            hasButton: true,
            hasImage: true,
            hasShadow: false,
            hasTab: false,
            id: 'thank-you',
            title: 'Thank You',
            titleColor: 'gray',
        };
        return (
            <Layout hideNav={true} needsSolidHeader={true} size={size}>
                <ConfirmationStyled>
                    <PageTitleBanner title="Apply" />
                    <Card {...thankYou}>
                        We’ve received your information and are looking forward
                        to keeping your business fueled and on the road.
                        <br />
                        <br />
                        Once we’ve reviewed your information our team will
                        follow up with the program best suited for your business
                        needs.{' '}
                    </Card>
                </ConfirmationStyled>
            </Layout>
        );
    }
}

export default withSize()(Confirmation);
