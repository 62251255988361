import styled from 'styled-components';

const CardStyled = styled.section`
    display: flex;
    flex-direction: column;
    width: 89%;
    margin-top: 10px;
    border-radius: ${props => props.theme.borderRadius.card};
    color: ${props =>
        props.bgColor === 'yellow' ? '#fff' : props.theme.colors.primaryGray};

    .card__image {
        background: url(${props => props.bgImage}) no-repeat;
        background-size: 100% auto;
        order: ${props => (props.imageAlignment === 'right' ? 1 : 0)};
        width: 100%;
        height: 190px;

        @media screen and (min-width: 768px) {
            background-size: auto 100%;
            width: 100%;
            height: 100%;
        }
    }

    .card__info {
        display: flex;
        flex-direction: column;
        align-items: ${props =>
            props.textAlignment === 'center' ? 'center' : 'flex-start'};
        padding: 46px 38px 54px 38px;
        background-color: ${props =>
            props.bgColor === 'yellow'
                ? props.theme.colors.primaryYellow
                : '#fff'};
        border-radius: ${props => props.theme.borderRadius.card};
        box-sizing: border-box;

        @media screen and (min-width: 768px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 100%;
        }

        .card__title {
            color: ${props =>
                props.titleColor === 'gray'
                    ? props.theme.colors.primaryGray
                    : props.theme.colors.truckerBurgerRed};
            text-align: ${props =>
                props.textAlignment === 'center' ? 'center' : 'left'};
        }

        .card__body-text {
            font-size: 14px;
            line-height: 30px;
            text-align: ${props =>
                props.textAlignment === 'center' ? 'center' : 'left'};
            @media screen and (min-width: 768px) {
                max-width: 460px;
                font-size: 1.125rem;
                line-height: 2rem;
            }
        }

        .emphasize {
            font-family: ${props => props.theme.fonts[1]};
        }

        .card__info__cta {
            color: ${props => props.theme.colors.primaryYellow};
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        button {
            margin-top: 28px;
            padding: 11px 71px 14px;
            align-self: ${props =>
                props.textAlignment === 'center' ? 'center' : 'left'};
        }

        &.center-align {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }

    &.with-image {
        border-radius: 0 ${props => props.theme.borderRadius.card}
            ${props => props.theme.borderRadius.card} 0;

        .card__info {
            border-radius: 0 ${props => props.theme.borderRadius.card}
                ${props => props.theme.borderRadius.card} 0;
        }

        @media screen and (min-width: 768px) {
            flex-direction: row;
        }
    }

    &.without-border-radius {
        border-radius: 0;

        .card__info {
            border-radius: 0;
        }
    }
`;

export default CardStyled;
