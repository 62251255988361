import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CardStyled from './card-styles';

import Button from '../Button';
import Tab from '../UI/Tab';
import TextDivider from '../UI/TextDivider';

import classNames from 'classnames';

class Card extends Component {
    constructor(props) {
        super(props);
        this.cardContent = (
            <React.Fragment>
                {this.props.hasTextDivider ? (
                    <TextDivider color={this.props.dividerColor} />
                ) : null}
                <p className="card__body-text">{this.props.children}</p>
                {this.props.hasButton ? (
                    <Button
                        buttonSidePadding={this.props.button.buttonSidePadding}
                        buttonVerticalPadding={
                            this.props.button.buttonVerticalPadding
                        }
                        className="card__button"
                        clicked={this.props.buttonClick}
                        url={this.props.button.url}
                        isExternal={this.props.button.isExternal}
                        color={this.props.button.color}
                        text={this.props.button.text}
                    />
                ) : null}
            </React.Fragment>
        );
    }

    render() {
        // this.props.onTabClicked(false);
        const cardClasses = classNames({
            'without-border-radius': !this.props.hasBorderRadius,
            'with-image': this.props.hasImage,
            'with-shadow': this.props.hasShadow,
        });

        const cardInfoClasses = classNames({
            card__info: true,
            'center-align': this.props.centerAlign,
        });

        return (
            <CardStyled
                bgImage={this.props.image && this.props.image.source}
                bgColor={this.props.bgColor}
                hasImage={this.props.hasImage}
                imageAlignment={
                    this.props.image && this.props.image.alignment
                        ? this.props.image.alignment
                        : null
                }
                textAlignment={this.props.textAlignment}
                titleColor={this.props.titleColor}
                id={this.props.id}
                className={cardClasses}
            >
                {this.props.hasTab ? (
                    <Tab
                        text={this.props.tabText}
                        clicked={() => this.toggleInfoHandler(this.props.id)}
                    />
                ) : null}
                {this.props.hasImage ? (
                    <div className="card__image"></div>
                ) : null}
                <div className={cardInfoClasses}>
                    <h2 className="card__title">{this.props.title}</h2>
                    {this.cardContent}
                </div>
            </CardStyled>
        );
    }
}

Card.propTypes = {
    bgColor: PropTypes.string,
    centerAlign: PropTypes.bool,
    button: PropTypes.shape({
        buttonSidePadding: PropTypes.string,
        buttonVerticalPadding: PropTypes.string,
        color: PropTypes.string,
        isExternal: PropTypes.bool,
        text: PropTypes.string,
        url: PropTypes.string,
    }),
    dividerColor: PropTypes.string,
    hasBorderRadius: PropTypes.bool,
    hasButton: PropTypes.bool,
    hasImage: PropTypes.bool,
    hasTab: PropTypes.bool,
    hasTextDivider: PropTypes.bool,
    image: PropTypes.shape({
        alignment: PropTypes.string,
        source: PropTypes.string,
    }),
    textAlignment: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleColor: PropTypes.string,
};

Card.defaultProps = {
    bgColor: 'white',
    centerAlign: false,
    dividerColor: 'yellow',
    hasBorderRadius: false,
    hasButton: false,
    hasImage: false,
    hasTab: false,
    hasTextDivider: true,
    textAlignment: 'left',
    titleColor: 'gray',
};

export default Card;
