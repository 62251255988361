import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TextDividerStyled = styled.span`
    display: block;
    width: 135px;
    height: 5px;
    margin: 22px 0;
    background-color: ${props =>
        props.color === 'yellow'
            ? props.theme.colors.primaryYellow
            : props.theme.colors.primaryGray};

    @media screen and (min-width: 1024px) {
        margin: 38px 0;
    }
`;

const TextDivider = ({ color }) => (
    <TextDividerStyled
        color={color}
        className="text-divider"
    ></TextDividerStyled>
);

TextDivider.propTypes = {
    color: PropTypes.string,
};

TextDivider.defaultProps = {
    color: 'yellow',
};

export default TextDivider;
