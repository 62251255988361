import styled from 'styled-components';

import ThankYouImage from '../../images/thank-you.png';

const ConfirmationStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    #thank-you {
        @media screen and (min-width: 768px) {
            align-self: flex-start;
            width: 100%;
            height: 550px;
            margin-top: 0;
        }

        .card__info {
            @media screen and (min-width: 768px) {
                display: flex;
                align-items: flex-start;
                height: 100%;

                .button {
                    align-self: flex-start;
                }
            }

            @media screen and (min-width: 1024px) {
                padding-left: 10%;
            }

            @media screen and (min-width: 1440px) {
                padding-left: 24%;
            }
        }

        .card__image {
            background: url(${ThankYouImage}) no-repeat;
            background-size: 100% auto;
            background-position-y: -80px;

            @media screen and (min-width: 768px) {
                order: 1;
                max-width: 720px;
                height: 550px;
                background-size: cover;
                background-position-y: 0;
            }
        }

        .button {
            margin-top: 20px;
        }
    }
`;

export default ConfirmationStyled;
